<template>
  <div class="flex flex-col gap-y-5">
    <!-- Loader -->
    <div class="flex justify-center w-full" v-if="isLoading">
      <loader></loader>
    </div>

    <!-- Consultation items -->
    <table class="border-collapse w-full" v-if="!isLoading">
      <!-- Header -->
      <thead>
        <tr>
          <th
            class="
              p-3
              font-bold
              text-xs
              uppercase
              text-gray-600
              border-0 border-b border-solid border-gray-300
              hidden
              lg:table-cell
            "
          >
            Date et heure
          </th>

          <th
            class="
              p-3
              font-bold
              text-xs
              uppercase
              text-gray-600
              border-0 border-b border-solid border-gray-300
              hidden
              lg:table-cell
            "
          >
            Suivi Medical
          </th>
          <th
            class="
              p-3
              font-bold
              text-xs
              uppercase
              text-gray-600
              border-0 border-b border-solid border-gray-300
              hidden
              lg:table-cell
            "
          >
            Documents
          </th>
          <th
            class="
              p-3
              font-bold
              text-xs
              uppercase
              text-gray-600
              border-0 border-b border-solid border-gray-300
              hidden
              lg:table-cell
            "
          >
            Statut
          </th>

          <th
            class="
              p-3
              font-bold
              text-xs
              uppercase
              text-gray-600
              border-0 border-b border-solid border-gray-300
              hidden
              lg:table-cell
            "
            v-if="authorized"
          >
            Actions
          </th>
        </tr>
      </thead>
      <!-- List -->
      <tbody v-for="(consultation, i) in consultations" :key="i">
        <tr
          class="
            bg-gray-100
            xl:bg-white
            flex
            lg:table-row
            flex-row
            lg:flex-row
            flex-wrap
            lg:flex-no-wrap
            shadow-inner
          "
        >
          <td
            class="
              w-full
              xl:w-auto
              py-5
              xl:py-3
              px-2
              xl:px-2
              text-gray-800
              border-0 border-b border-solid border-gray-200
              block
              lg:table-cell
              relative
              lg:static
            "
          >
            <div
              class="
                block
                xl:hidden
                font-bold
                text-xs
                uppercase
                text-gray-600
                mb-2
              "
            >
              Date et heure
            </div>
            <span class="capitalize font-EffraR">
              {{
                moment()(consultation.startAt).format("HH:mm, ddd DD MMM YYYY")
              }}</span
            >
          </td>
          <td
            class="
              w-full
              xl:w-auto
              py-5
              xl:py-3
              px-2
              xl:px-2
              text-gray-800
              border-0 border-b border-solid border-gray-200
              block
              lg:table-cell
              relative
              lg:static
            "
          >
            <div
              class="
                block
                xl:hidden
                font-bold
                text-xs
                uppercase
                text-gray-600
                mb-2
              "
            >
              Suivi Medical
            </div>
            <div class="flex justify-between items-center">
              <div
                style="width: 3.6rem"
                class="
                  h-8
                  flex
                  items-center
                  bg-gray-300
                  rounded-full
                  p-1
                  duration-300
                  ease-in-out
                "
                :class="{ 'bg-green-400': consultation.hasFollowUp }"
              >
                <div
                  class="
                    bg-white
                    w-8
                    h-8
                    rounded-full
                    shadow-md
                    transform
                    duration-300
                    ease-in-out
                    flex
                    justify-center
                    items-center
                  "
                  :class="{ 'translate-x-6': consultation.hasFollowUp }"
                >
                  <small style="font-size: 10px" class="font-EffraR">{{
                    consultation.hasFollowUp ? "ON" : "OFF"
                  }}</small>
                </div>
              </div>
            </div>
          </td>
          <td
            class="
              w-full
              xl:w-auto
              py-5
              xl:py-3
              px-2
              xl:px-2
              text-gray-800
              border-0 border-b border-solid border-gray-200
              block
              lg:table-cell
              relative
              lg:static
            "
          >
            <div
              class="
                block
                xl:hidden
                font-bold
                text-xs
                uppercase
                text-gray-600
                mb-2
              "
            >
              Documents
            </div>
            <div class="flex flex-row items-center">
              <a
                v-if="consultation.prescription"
                target="_blank"
                :href="`https://api.dok.ma/api/v1/download/document/${
                  consultation.prescription._id
                }?token=${$auth.getToken()}`"
                class="
                  bg-transparent
                  border border-solid border-dokBlue-ultra
                  text-dokBlue-ultra
                  font-semibold
                  text-base
                  py-2
                  px-3
                  rounded-cu
                  mr-1
                  cursor-pointer
                "
              >
                Prescription
              </a>
              <a
                v-if="consultation.analysisResult"
                :href="`https://api.dok.ma/api/v1/download/document/${
                  consultation.analysisResult._id
                }?token=${$auth.getToken()}`"
                target="_blank"
                class="
                  bg-transparent
                  border border-solid border-dokBlue-ultra
                  text-dokBlue-ultra
                  font-semibold
                  text-base
                  py-2
                  px-3
                  rounded-cu
                  ml-1
                  cursor-pointer
                "
              >
                Résultats d'analyse
              </a>
            </div>
          </td>
          <td
            class="
              w-full
              xl:w-auto
              py-5
              xl:py-3
              px-2
              xl:px-2
              text-gray-800
              border-0 border-b border-solid border-gray-200
              block
              lg:table-cell
              relative
              lg:static
            "
          >
            <div
              class="
                block
                xl:hidden
                font-bold
                text-xs
                uppercase
                text-gray-600
                mb-2
              "
            >
              Statut
            </div>
            <div
              v-if="consultation.sharedWith.includes($auth.getUserId())"
              class="text-dokBlue-ultra flex items-center font-semibold"
            >
              <i class="fas fa-retweet mr-1"></i> Il a été partagé avec vous
            </div>
            <div
              class="flex flex-col xl:flex-row font-EffraR"
              v-html="$statusConsultation(consultation.status)"
            ></div>
          </td>
          <td
            class="
              w-full
              xl:w-auto
              py-5
              xl:py-3
              px-2
              xl:px-2
              text-gray-800
              border-0 border-b border-solid border-gray-200
              block
              lg:table-cell
              relative
              lg:static
            "
          >
            <div
              class="
                block
                xl:hidden
                font-bold
                text-xs
                uppercase
                text-gray-600
                mb-2
              "
              v-if="authorized"
            >
              Actions
            </div>
            <div class="flex gap-x-2">
              <dok-button
                size="md"
                v-if="authorized"
                @click.native="showConsulter(consultation._id)"
                custom-class="w-full"
                :bg="openConsultation === consultation._id ? 'rose' : 'blue'"
              >
                <i class="fas fa-eye mr-1 text-lg"></i> Consulter
              </dok-button>

              <dok-share-consultation :data="consultation">
                <template v-slot:button>
                  <dok-button
                    size="md"
                    bg="trans"
                    custom-class="flex items-center justify-center"
                  >
                    <svg
                      class="cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="#2E6BFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18 8c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM6 15c1.657 0 3-1.343 3-3S7.657 9 6 9s-3 1.343-3 3 1.343 3 3 3zM18 22c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98"
                      />
                    </svg>
                  </dok-button>
                </template>
              </dok-share-consultation>
            </div>
          </td>
        </tr>
        <tr v-if="openConsultation === consultation._id">
          <td colspan="5" class="px-5 py-5">
            <summary-consultation
              :consultationID="consultation._id"
            ></summary-consultation>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Action load more items -->
    <dok-button
      v-if="!isLoading && loader && consultations.length > 14"
      @click.native="onLoadMore"
      size="lg"
      custom-class="w-full"
    >
      Voir plus
    </dok-button>
  </div>
</template>

<script>
import moment from "moment";
const loader = () => import("@/views/global-components/loader");
const SummaryConsultation = () =>
  import("@/views/global-components/ConsultationSummary");

export default {
  data() {
    return {
      isLoading: true,
      authorized: true,
      loader: true,
      selectConsultation: null,
      consultations: [],
      page: 1,
      openConsultation: null,
    };
  },
  created() {
    this.getConsultation();
  },
  mounted() {
    const doctor = this.$auth.getUserInfo();
    if (doctor.role == "TEAM-MEMBER") {
      this.authorized = false;
    }
  },
  methods: {
    async getConsultation() {
      this.$store.dispatch("dmp/GET_CONSULTATIONS", {
        page: this.page,
        patientId: this.$route.params.id,
        onData: this.onData,
      });
    },
    async onLoadMore() {
      this.isLoading = true;
      this.page++;
      try {
        this.$store.dispatch("dmp/GET_CONSULTATIONS", {
          page: this.page,
          patientId: this.$route.params.id,
          onData: ({ ok, data }) => {
            if (!data.length) this.loader = false;
            this.consultations = [...this.consultations, ...data];
            this.isLoading = false;
          },
        });
      } catch (error) {
        this.isLoading = false;
      }
    },
    showConsulter(id) {
      if (this.openConsultation === id) {
        this.openConsultation = null;
        return;
      }

      this.$forceUpdate();

      this.openConsultation = id;
    },
    onData({ ok, data, authorized }) {
      if (ok) {
        this.consultations = data;
      }
      this.isLoading = false;
    },
    moment() {
      return moment;
    },
  },
  components: { loader, SummaryConsultation },
};
</script>
